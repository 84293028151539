import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: '40vh',
    minWidth: '40vw'
  },
  dialogTitle: {
    backgroundColor:  "#A60000", // Colore di sfondo rosso
    color: 'white', // Colore del testo bianco
  },
  dialogContent: {
    backgroundColor: '#ffcccc', // Colore di sfondo rosso chiaro
  },
  dialogActions: {
    
  },
  messageText: {
    color: 'black', // Colore del testo nero
    marginTop: theme.spacing(4),
    fontSize: '1.2rem'
  },
  alertMessage: {
    color: 'black', // Colore del testo nero
    marginTop: theme.spacing(6), // Margine superiore per posizionare più in basso
    fontSize: '1.2rem',
  },
}));

const DialogRecensioni = ({ open, toggleOpen }) => {
  const classes = useStyles();
  const [randomMessage, setRandomMessage] = useState(''); 
  const [alertMessage, setAlertMessage] = useState('');

  const messages = [
    "Chiedi al paziente se è soddisfatto del trattamento ricevuto e invitalo a scrivere una recensione.",
    "Fai vedere al paziente che accostando il suo cellulare al qr code sul bancone potrà scrivere una recensione.",
    "Le recensioni dei pazienti richiamano altri pazienti e ci fanno comparire per primi su internet.",
    "Le recensioni sono importanti per la crescita del nostro studio."
  ];

  const alert_messages = [
    "Richiedi una recensione quanto prima per evitare che il sistema generi una criticità e vada in blocco.",
    "Affrettati a richiedere una recensione su Facebook altrimenti il sistema genera una criticità e va in blocco.",
    "Ottieni una recensione entro questa sera per non generare una criticità bloccante.",
    "Una recensione al giorno toglie le criticità di torno."
  ];


  useEffect(() => {
    if (open) {
      let randomIndex = Math.floor(Math.random() * messages.length);
      setRandomMessage(messages[randomIndex]);
      randomIndex = Math.floor(Math.random() * alert_messages.length);
      setAlertMessage(alert_messages[randomIndex]);
    }
  }, [open]);

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      toggleOpen();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
        ATTENZIONE ⚠️
      </DialogTitle>
      <DialogContent >
      <DialogContentText className={classes.messageText} id="random-message">
          {randomMessage}
        </DialogContentText>
        <DialogContentText className={classes.alertMessage} id="alert-dialog-description">
          {alertMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={toggleOpen} color="primary">
          Chiudi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogRecensioni;