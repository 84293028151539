import React from 'react'
import Elemento from "../components/Elemento"

import { SingleSelect } from "react-select-material-ui"
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Icon  from '@material-ui/core/Icon'
import DialogDataFirma from '../dialogs/DialogDataFirma'
import RefreshIcon from '@material-ui/icons/Refresh'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NumberFormat from 'react-number-format'
import MomentUtils from '@date-io/moment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import IconButton from '@material-ui/core/IconButton'
import FolderIcon from '@material-ui/icons/Folder'
import DeleteIcon from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import Tooltip from '@material-ui/core/Tooltip'
import Cancel from '@material-ui/icons/CancelOutlined'
import { Toolbar } from '@material-ui/core'
import api from "../api_calls/api"
import PreviewPdf from "../components/PreviewPdf"
import PdfIcon from '@material-ui/icons/PictureAsPdf'
import calc from '../utils/calc'
import { withSnackbar } from 'notistack'
import amnp from '../utils/array_ma_non_posso'
import withConfirm from 'material-ui-confirm'
import { KeyboardDatePicker } from "@material-ui/pickers"


class DiagrammaDentale extends React.Component {
    state = {
      options_trattamenti: [],
      curr_prezzo: 0,
      curr_prestazione: "",
      count_elementi: 0,
      elementi: [],
      trattamenti_paziente: [],
      piano_obj: this.props.piano_obj,
      curr_nome: (this.props.piano_obj.nome === "piano") ? "" : this.props.piano_obj.nome,
      sconto_edit: false,
      curr_sconto: this.props.piano_obj.prezzo_totale - this.props.piano_obj.prezzo_scontato_totale,
      tipo_sconto: 'abs',
      sconto_orig: this.props.piano_obj.prezzo_totale - this.props.piano_obj.prezzo_scontato_totale,
      in_preview: false,
      tipo: 'P',
      resetsel: 1,
      openDfDialog: false,
      conferma_firma: false,
      in_modifica: false
    }

  componentDidMount() {
      this.loadPrestazioni()
  }

  componentDidUpdate(propsPrecedenti) {
    if (this.props.piano_obj !== propsPrecedenti.piano_obj) {
      this.loadPrestazioni()
    }
  }

loadPrestazioni = () => {
  const url_prestazioni_paziente = `${process.env.REACT_APP_API_URL}/dc/prestazioni_paziente/${this.props.paziente.id}/${this.props.piano}`
    //alert(url_prestazioni_paziente)
    fetch(url_prestazioni_paziente, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
    })
        .then(result => result.json())
        .then(result => {
          this.setState({
            trattamenti_paziente: result.results
          })
      })
}  

removeTrattamento = (id) => {
  const url_remove_trattamento = `${process.env.REACT_APP_API_URL}/dc/remove_trattamento/${id}`
  api.call_get(url_remove_trattamento, (result) => {
    if (result === "OK") {
      this.props.enqueueSnackbar(`Trattamento cancellato`, {variant:  'success', autoHideDuration: 5000})
    } else {
      this.props.enqueueSnackbar(result, {variant:  'error', autoHideDuration: 5000})
    }
    this.setState({in_modifica: false}, this.loadPrestazioni);
  })

  /*fetch(url_remove_trattamento, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('token')}`
    }
  })
  .then(result => result)
  .then(result => {
      console.log(result)
      this.loadPrestazioni()
  })*/
}

handleInputPrestazione = (evt) => {
    const url_trattamenti = `${process.env.REACT_APP_API_URL}/dc/scelta_trattamenti/${this.props.paziente.id}/?q=` 
    fetch(url_trattamenti + evt.target.value, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
    })
        .then(result => result.json())
        .then(result => {
          const trattamenti = result.results.map((row, index) => {
            return (
              {
                  value: row,
                  label: row.nome_completo,
                  prezzo: row.prezzo,
                  trattamento: row.trattamento,
                  prodotto: row.prodotto
              }
            )
          })
          this.setState({
            options_trattamenti: trattamenti
          })
      })
}

setDiscountEditMode = (mode) => {
  this.setState({
    sconto_edit: mode
  })
}

cambiaSconto = (val) => {
  try {
    let tratt = this.state.trattamenti_paziente
    calc.setPrezziScontati(tratt, val)
    this.setState({
      curr_sconto: val,
      trattamenti_paziente: tratt
    })
  } catch(e) {
    this.cambiaSconto(0)
  }
}

cambiaScontato = (val) => {
    const  sconto = this.prezzoTotale() - val
    this.cambiaSconto(sconto)
}

setTipoSconto = (tipo) => {
  console.log("TIPO SCONTO: " + tipo)
  console.log("TIPO SCONTO: " + JSON.stringify(tipo))
  this.setState({
    tipo_sconto: tipo
  })
}

ricaricaPiano = () => {
  const url_pdc = `${process.env.REACT_APP_API_URL}/dc/piano_terapeutico/${this.props.piano_obj.id}/`
      let method = 'GET'
      api.call_get(
        url_pdc, 
       (res) => {
            this.props.updatePianoObj(res);
            this.props.enqueueSnackbar(`Dati aggiornati.`, {variant:  'success', autoHideDuration: 4000})
        },
        (err) => {
          try {
            let msg = err.response.data.split("\n")[1];
            this.props.enqueueSnackbar(`Errore caricamento dati: ${msg}`, {variant:  'error', autoHideDuration: 8000})
          } catch(e) {
            this.props.enqueueSnackbar(`Errore caricamento dati: ${e}`, {variant:  'error', autoHideDuration: 8000})
          }
        },
        method)
}

eseguito = (id, esecuzione_id) => {
  const url_eseguito = `${process.env.REACT_APP_API_URL}/dc/trattamento_eseguito/${id}/${esecuzione_id}` 
    fetch(url_eseguito, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
    })
        .then(result => {
          this.loadPrestazioni()
      })
}

annulla = (id) => {
  const url_annulla = `${process.env.REACT_APP_API_URL}/dc/trattamento_annulla/${id}` 
    fetch(url_annulla, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
    })
        .then(result => {
          this.loadPrestazioni()
      }).catch(err => {
        this.props.enqueueSnackbar("Solo un amministratore può annullare un trattamento approvato.", {variant:  'error', autoHideDuration: 5000});
        return
    });
}

haEseguiti() {
  return (this.prezzoEseguito() > 0);
}

undo_modifica_pdc = (id) => {
  let curr_url = `${process.env.REACT_APP_API_URL}/dc/undo_modifica_pdc/${id}/`;
  fetch(curr_url, {
    headers: {
      Authorization: `JWT ${localStorage.getItem('token')}`
    }
  })
      .then(result => result.json())
      .then(result => {
        const piani  = result.results
        if(piani[0].result && piani[0].result === 'ERROR') {
          this.props.enqueueSnackbar(piani[0].message, {variant:  'error', autoHideDuration: 5000});
        } else {
          this.setState({
            piano_obj: piani[0],
            in_modifica: false
          })
        }
    }).catch(err => {
      //alert(JSON.stringify(err));
      this.props.enqueueSnackbar("Errore.", {variant:  'error', autoHideDuration: 5000})
      return
  });
}

approvato = (id, approva, in_modifica) => {
  let curr_url = `${process.env.REACT_APP_API_URL}/dc/piano_approvato/${id}/` 
  if(!approva) {
    curr_url = `${process.env.REACT_APP_API_URL}/dc/piano_rifiutato/${id}/` 
  }
    fetch(curr_url, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
    })
        .then(result => result.json())
        .then(result => {
          const piani  = result.results
          if(piani[0].result && piani[0].result === 'ERROR') {
            this.props.enqueueSnackbar(piani[0].message, {variant:  'error', autoHideDuration: 5000});
          } else {
            this.setState({
              piano_obj: piani[0],
              in_modifica: in_modifica
            })
          }
      }).catch(err => {
        //alert(JSON.stringify(err));
        this.props.enqueueSnackbar("Solo un amministratore può modificare un piano di cure approvato.", {variant:  'error', autoHideDuration: 5000})
        return
    });
}

completato = (id) => {
  const url_completato = `${process.env.REACT_APP_API_URL}/dc/piano_completato/${id}/` 
    fetch(url_completato, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
    })
        .then(result => result.json())
        .then(result => {
          const piani  = result.results
          this.setState({
            piano_obj: piani[0]
          })
      }) 
}

scontato = (id) => {
  const url_scontato = `${process.env.REACT_APP_API_URL}/dc/piano_scontato/${id}/` 
    fetch(url_scontato, {
      headers: {
        Authorization: `JWT ${localStorage.getItem('token')}`
      }
    })
        .then(result => result.json())
        .then(result => {
          const piani  = result.results
          this.setState({
            piano_obj: piani[0]
          })
      }) 
}

handleTariffa = (target) => {  
  const value = target.type === 'checkbox' ? target.checked : target.value
  let curr_prestazione = this.state.curr_prestazione
  let count_elementi = this.state.count_elementi
  let elementi = this.state.elementi
  
  if (target.name === "prestazione") {
    curr_prestazione = value
    elementi = []
    if (curr_prestazione) {
      //se non ho nessuna posizione valorizzata imposto almeno posizione_generale
      if(!curr_prestazione.posizione_elemento && !curr_prestazione.posizione_quadrante && !curr_prestazione.posizione_arcata) {
        curr_prestazione.posizione_generale = true
      }
      if (curr_prestazione.posizione_generale && !curr_prestazione.posizione_elemento && !curr_prestazione.posizione_quadrante && !curr_prestazione.posizione_arcata) {
        elementi.push("GEN")
      }
    }
    count_elementi = elementi.length || 1
  }
    
  if (target.name === "elementi") {
    elementi = value
    count_elementi = value.length || 1
  }

  if (curr_prestazione) {
          this.setState({
            curr_prezzo: value.prezzo
          })
  }
  
  this.setState({
      curr_prestazione: curr_prestazione,
      count_elementi: count_elementi,
      elementi: elementi
  }) 
}

aggiungiPrestazione = (val) => {
  console.log("aggiungiPrestazione ")
  if (this.state.curr_nome.length < 10) {
    this.props.enqueueSnackbar("Prima di aggiungere le prestazioni scrivi un nome di almeno 10 caratteri per il  piano di cure.", {variant:  'error', autoHideDuration: 5000})
    return
  }
  const curr_prestazione = this.state.curr_prestazione
  const curr_prezzo = this.state.curr_prezzo
  const elementi = this.state.elementi
  
  if(curr_prestazione && elementi.length > 0) {
    
    const url = `${process.env.REACT_APP_API_URL}/dc/aggiungi_prestazione/${this.props.paziente.id}/${this.props.piano}` 
    var data = new FormData();
    data.append( "json", JSON.stringify({
      id_listino: curr_prestazione.listino,
      id_prestazione: curr_prestazione.trattamento,
      elementi: elementi,
      prezzo: curr_prestazione.prezzo,
      id_prodotto: curr_prestazione.prodotto
    }))
    fetch(url, {
        method: "POST",
        body: data,
        Authorization: `JWT ${localStorage.getItem('token')}`
      }).then(result => result)
      .then(result => {
        this.setState({
          curr_prestazione: null,
          options_trattamenti: [],
          curr_prezzo: 0,
          elementi: [],
          resetsel: new Date().getTime(),
          in_modifica: false
        })
        this.loadPrestazioni()
      })
  } else {
    this.props.enqueueSnackbar("Scegli la prestazione e almeno un elemento.", {variant:  'error', autoHideDuration: 5000})
  }
  
}

prezzoTotale = () => {
  let pt = 0.0
  this.state.trattamenti_paziente.map((item) => {
    pt += parseFloat(item.prezzo_base) 
  })
  return pt
}

prezzoEseguito = () => {
  let pt = 0.0
  try {
    this.state.trattamenti_paziente.map((item) => {
      item.stati_esecuzione.map((stato) => {
        pt += parseFloat(stato.prezzo_per_fattura) 
      })
  })
  } catch(e) {
  ;
}
  return pt
}

statoEseguito = (trattamento) => {
  let res = "Da eseguire"
  try {
    
    trattamento.stati_esecuzione.map((stato_esecuzione) => {
      if (stato_esecuzione.stato === "Eseguito" || stato_esecuzione.stato === "Annullato") {
        res = stato_esecuzione.stato
      }
    })
  } catch(e) {
  ;
}
  return res
}


clickElemento = (val) => {
  let { elementi } = this.state
  if(elementi.includes(val)) {
    elementi = elementi.filter(el => el !== val)
  } else {
    elementi.push(val)
  }
  //this.setState({elementi: elementi})
  this.handleTariffa({name: "elementi", value: elementi, "type": "select"})
}

handleElementi = (val) => {
  const options_elementi = [
    "11", "12", "13", "14", "15", "16", "17", "18",
    "21", "22", "23", "24", "25", "26", "27", "28",
    "31", "32", "33", "34", "35", "36", "37", "38",
    'Q1', 'Q2', 'Q3', 'Q4',
    'AS', 'AI', 
    'GEN'
  ]

  
  let letti = val.split(",")
  letti.forEach((element, index) => {
        letti[index] = element.trim()
  })
  letti = letti.filter(el => options_elementi.includes(el))
  this.handleTariffa({name: "elementi", value: letti, "type": "text"})
}

handleNome(val) {
  this.setState({
    curr_nome: val
  })
}

cambiaNome() {
  if(!this.state.curr_nome || this.state.curr_nome.length < 10) {
    this.props.enqueueSnackbar(`Usa un nome di almeno 10 caratteri.`, {variant:  'error', autoHideDuration: 5000})
    return
  }
  const url = `${process.env.REACT_APP_API_URL}/dc/modifica_nome_piano/${this.state.piano_obj.id}`
    const el = {
      nome: this.state.curr_nome
    }
    api.call_post(url, el, (res) => {
      if(res.result == "OK") {
        this.setState({
          piano_obj: {
            ...this.state.piano_obj,
            nome: this.state.curr_nome
          }
        })
        this.props.enqueueSnackbar(`Nome del piano salvato.`, {variant:  'success', autoHideDuration: 5000})
      }
    }, (err) => {
      //alert(JSON.stringify(err))
    }, "POST")
}

getScontoPerc = () => {
  const cs = this.state.curr_sconto
  const tot = this.prezzoTotale()
  const res = Number((cs / tot * 100).toFixed(2))
  return parseFloat(res)
}

cambiaScontoPerc = (perc) => {
  let val = perc * this.prezzoTotale() / 100
  val = Number(val.toFixed(2))
  this.cambiaSconto(val)
}

salvaScontoPrestazione = () => {
  const tratt = this.state.trattamenti_paziente
  tratt.map((item, idx) => {
    const url_prestazione = `${process.env.REACT_APP_API_URL}/dc/api_prestazione/${item.id}`
    const el = {
      id: item.id,
      prezzo_scontato: item.prezzo_scontato,
      sconto_calcolato: false
    }
    api.call_post(url_prestazione, el, (res) => {
      console.log(JSON.stringify(res))
    }, (err) => {
      //alert(JSON.stringify(err))
    }, "POST")
  })
}


salvaSconto = () => {
    const url_sconto = `${process.env.REACT_APP_API_URL}/dc/salva_sconto/${this.state.piano_obj.id}`
    let el = {
      id: this.state.piano_obj.id,
      prezzo_scontato: Number((this.prezzoTotale() - this.state.curr_sconto).toFixed(2)),
      sconto_val_ass: this.state.curr_sconto,
      trattamenti: []
    }
    el.trattamenti = this.state.trattamenti_paziente.map((item) => {
      return {
        id: item.id,
        prezzo_scontato: item.prezzo_scontato,
        sconto_calcolato: true
      }
    })
    api.call_post(url_sconto, el, (res) => {
      if (res.esito === 'OK') {
        this.props.enqueueSnackbar(res.msg, {variant:  'success', autoHideDuration: 5000})
        this.setState({
          piano_obj: {
            ...this.state.piano_obj,
            stato: 'Scontato'
          }
        })
      } else {
        this.props.enqueueSnackbar(res.msg, {variant:  'error', autoHideDuration: 5000})
        this.setState({
          curr_sconto: 0
        })
      }
    }, (err) => {
      this.props.enqueueSnackbar(`Errore ` + JSON.stringify(err), {variant:  'error', autoHideDuration: 5000})
    }, "POST")
}

urlPdf() {
    if(!this.state.piano_obj.data_firma_paziente && (this.state.piano_obj.stato === "Approvato" || this.state.piano_obj.stato === "Non approvato")) {

    } else {
      const url_pdf = `${process.env.REACT_APP_API_URL}/dc/stampa_preventivo/${this.state.piano_obj.id}`
      return url_pdf
    }
}

hasAnnullati() {
  let res = false
  for(let k=0; k < this.state.trattamenti_paziente.length; ++k) {
    if(this.state.trattamenti_paziente[k].annullato) {
      res = true
      break
    }
  }
  return res
}

importoAnnullati() {
  let res = 0.0
  for(let k=0; k < this.state.trattamenti_paziente.length; ++k) {
    if(this.state.trattamenti_paziente[k].annullato) {
      res += Number(this.state.trattamenti_paziente[k].prezzo_scontato)
      break
    }
  }
  return res
}

handleCloseDfDialog() {

}

handleSetDF() {

}


setDf(pdc) {
      
      this.setState({
        piano_obj: {
          ...this.state.piano_obj,
          data_firma_paziente: pdc.data_firma_paziente
        },
        in_preview: true,
        openDfDialog: false
      })
}

toggleDfOpen() {

    this.setState({
      openDfDialog: !this.state.openDfDialog
    })
  
}

toggleFinanziato() {
      const url = `${process.env.REACT_APP_API_URL}/dc/piano_finanziato/${this.state.piano_obj.id}/`
  api.call_post(url, {}, (res) => {

        let msg = "Piano impostato come finanziato";
        if(this.state.piano_obj.finanziato) {
          msg = "Piano impostato come NON finanziato";
        }
        this.props.enqueueSnackbar(msg, {variant:  'success', autoHideDuration: 5000})
        this.setState({
          piano_obj: {
            ...this.state.piano_obj,
            finanziato: !this.state.piano_obj.finanziato
          }
        })

    }, (err) => {
      //alert(JSON.stringify(err))
    }, "POST")

}

stampaPdc() { 
  
  if(!this.state.in_preview && (this.state.piano_obj.stato === "Accettato" || this.state.piano_obj.stato === "Non accettato") && !this.state.piano_obj.data_firma_paziente) { 
    setTimeout(function() {
        this.setState({conferma_firma: true }) 
      }.bind(this), 20000)
  }
  this.setState({ in_preview: !this.state.in_preview }) 
}
      

render() {
        const moment = new MomentUtils()
        const openDfDialog = this.state.openDfDialog  
        const {in_preview} = this.state
        const options_elementi = [
          "11", "12", "13", "14", "15", "16", "17", "18",
          "21", "22", "23", "24", "25", "26", "27", "28",
          "31", "32", "33", "34", "35", "36", "37", "38",
          'Q1', 'Q2', 'Q3', 'Q4',
          'AS', 'AI', 
          'GEN'
        ].map((row, index) => {
          return (
            {
                value: row,
                label: row
            }
          )
        })

        const trattamenti_items = this.state.trattamenti_paziente.map((item, idx) => 
            <li key={item.id}>Elementi: {item.applicato_a} Trattamento: {item.trattamento.nome} Prezzo: {item.prezzo_scontato} {item.prezzo_scontato_currency}</li>
        )

        const {options_trattamenti} = this.state
        const {curr_prezzo} = this.state
        const {resetsel} = this.state  
        const {count_elementi} = this.state 
        const sconto_edit = (this.state.piano_obj.stato === "Scontato")
        const {curr_sconto} = this.state
        const {tipo_sconto} = this.state
        let tot_prezzo_prest = curr_prezzo * count_elementi
        console.log("tot_prezzo_prest: " + tot_prezzo_prest + " " + curr_prezzo + " " + count_elementi)

        if (isNaN(tot_prezzo_prest)) {
          tot_prezzo_prest = ''
        }
        const curr_nome = this.state.curr_nome
        console.log("openDfDialog: " + openDfDialog)
        return(<div displayType={'flex'}>
              <DialogDataFirma pdc={this.state.piano_obj} setDf={this.setDf.bind(this)} toggleOpen={() => {this.toggleDfOpen()}} open={openDfDialog} />  
              <Grid item sx={12} style={{'margin-top': 20}}>
              <div >
                  <h2>{this.state.piano_obj.nome} #{this.state.piano_obj.id} stato: {this.state.piano_obj.stato}  {(this.state.piano_obj.stato === "Da autorizzare" || this.state.piano_obj.stato === "Non autorizzato") && <Button color="secondary" variant="contained" onClick={this.ricaricaPiano}><RefreshIcon /></Button>}
                  {(this.state.piano_obj.stato === "Da autorizzare") && <span style={{color: "#A60000"}}>&nbsp;<br/><b>Per completare il preventivo chiamare Magdalo o Riccardo.</b></span>}
                  {(this.state.piano_obj.stato === "Non autorizzato") && <span style={{color: "#A60000"}}>&nbsp;<br/><b>Il preventivo non è stato autorizzato.</b></span>}    
                  {(this.state.piano_obj.stato === "Accettato" || this.state.piano_obj.stato === "Non accettato") && this.state.piano_obj.data_firma_paziente && <span style={{color: "#008500"}}>&nbsp;<b>FIRMATO IL {moment.moment(this.state.piano_obj.data_firma_paziente).format('DD/MM/YY')}</b></span>}
                  {(this.state.piano_obj.stato === "Accettato" || this.state.piano_obj.stato === "Non accettato") && !this.state.piano_obj.data_firma_paziente && <span style={{color: "#A60000"}}>&nbsp;<b>DA FIRMARE</b></span>}
                  </h2> 
                  <h2>{this.state.piano_obj.stato !== "Non autorizzato" && this.state.piano_obj.stato !== "Da autorizzare" && this.state.piano_obj.stato !== "In preparazione" && this.state.piano_obj.stato !== "Scontato" && this.state.piano_obj.stato !== "Completato" && <>
                  <Button variant="contained" color="secondary" onClick={() => { this.approvato(this.state.piano_obj.id, true, true) }}>Modifica</Button>&nbsp;
                  {this.state.piano_obj.eseguibile && this.state.piano_obj.check_chiusura &&
                  <Button variant="contained" color="default"
                  onClick={this.props.confirm(() => { this.completato(this.state.piano_obj.id) }, {
                    title: 'Completa piano',
                    description: "Vuoi davvero impostare questo piano come completato?",
                    confirmationText: 'Sì',
                    cancellationText: 'No'
                    })}
                  >Completa</Button>}
                  {this.state.piano_obj.eseguibile && !this.state.piano_obj.check_chiusura &&
                  <Button variant="contained" color="default"
                  onClick={this.props.confirm(() => { }, {
                    title: 'Completa piano',
                    description: "Il piano non può essere chiuso perchè ha trattamenti eseguiti da fatturare a saldo.",
                    confirmationText: 'OK',
                    cancellationText: ''
                    })}
                  >Completa</Button>}

                  &nbsp;
                  </>}
                  {this.state.piano_obj.stato === "Completato" && <>
                  
                  <Button variant="contained" color="default" onClick={() => { this.completato(this.state.piano_obj.id) }}>Riapri</Button>
                  &nbsp;
                  </>}
                  {this.state.piano_obj.stato === "Scontato" && <>
                  <Button variant="contained" color="secondary" onClick={() => { this.scontato(this.state.piano_obj.id) }}>Modifica</Button>
                  &nbsp;
                  </>}
                  {this.state.piano_obj.stato !== "Non autorizzato" && this.state.piano_obj.stato !== "Da autorizzare" && this.state.piano_obj.stato !== "In preparazione" &&  <Button variant="contained" color="default" onClick={() => {this.stampaPdc()}}>Stampa&nbsp;<PdfIcon/></Button>}
                  {this.state.conferma_firma && !this.state.piano_obj.data_firma_paziente && (this.state.piano_obj.stato === "Accettato" || this.state.piano_obj.stato === "Non accettato") && <>&nbsp;<Button variant="contained" color="primary" onClick={() => { this.setState({ openDfDialog: !this.state.openDfDialog })}}>Conferma firma&nbsp;</Button></>}

                  &nbsp;&nbsp;{this.state.piano_obj.finanziato && <>Piano finanziato</>}
                  </h2>
                  {this.state.piano_obj.stato === "In preparazione" && <>
                  <form style={{ display: 'flex' }}>
                  <Grid container>
                  <Grid item xs={12}>
                    <b>Nome piano:</b> <TextField 
                    value={curr_nome} 
                    onChange={(evt)=> {this.handleNome(evt.target.value)}}
                    onBlur={(val)=> {this.cambiaNome()}}
                    error={curr_nome !== "piano" && curr_nome.length < 10}
                    id="nome_piano"
                    /> 
                    {/*&nbsp;<Button 
                    size="small"
                    value="cambia_nome" 
                    variant="contained" color="secondary" 
                    onClick={(val)=> {this.cambiaNome()}}
                    disabled={curr_nome === this.state.piano_obj.nome}
                    >
                      Salva nome piano
                    </Button>*/}
                  </Grid>  
                  <Grid item xs={9}>
                      <SingleSelect
                        key = {`t_key_${resetsel}`}
                        name={'prestazione'}
                        label={'Prestazione'}
                        helperText={'Cerca la prestazione'}
                        style={{ width: "100%" }}
                        options={options_trattamenti}
                        onInput={this.handleInputPrestazione}
                        onChange={(val)=> {this.handleTariffa({ name:'prestazione', value: val })}}
                        SelectProps={{
                          msgNoOptionsAvailable: "Digita parte del nome della prestazione",
                          msgNoOptionsMatchFilter: "Non ci sono prestazioni che corrispondono alla ricerca",
                          isClearable: true
                        }}
                      />
                    </Grid>  
                    <Grid item xs={3} align="right" style={{"vertical-align": "bottom"}}>
                      <NumberFormat value={curr_prezzo} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </Grid>
                  </Grid>
                  </form>
                  <Grid container >
                    <Grid item xs={12}>
                      <Grid container spacing={0}>
                          {this.state.tipo === 'P' && <>
                          <Grid item xs={5}>
                            <Elemento elemento="18" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="17" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="16" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="15" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="14" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="13" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="12" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="11" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                          </Grid>
                          <Grid item xs={5}>
                            <Elemento elemento="21" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="22" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="23" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="24" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="25" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="26" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="27" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="28" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                          </Grid></>}
                          {this.state.tipo === 'D' && <>
                          <Grid item xs={5}>
                            <Elemento elemento="55" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="54" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="53" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="52" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="51" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                          </Grid>
                          <Grid item xs={5}>
                            <Elemento elemento="61" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="62" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="63" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="64" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="65" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            </Grid>
                          </>}
                          <Grid item xs={2}>
                            <Elemento elemento="Q1" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="Q2" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="AS" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="GEN" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                          </Grid>    
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container >
                        {this.state.tipo === 'P' && <>
                          <Grid item xs={5}>
                            <Elemento elemento="48" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="47" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="46" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="45" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="44" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="43" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="42" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="41" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                          </Grid>
                          <Grid item xs={5}>
                            <Elemento elemento="31" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="32" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="33" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="34" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="35" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="36" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="37" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="38" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            </Grid>
                          </>}  
                          {this.state.tipo === 'D' && <>
                          <Grid item xs={5}>
                            <Elemento elemento="85" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="84" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="83" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="82" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="81" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                          </Grid>
                          <Grid item xs={5}>
                            <Elemento elemento="71" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="72" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="73" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="74" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="75" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            </Grid>
                          </>}
                          <Grid item xs={2}>
                            <Elemento elemento="Q4" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="Q3" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <Elemento elemento="AI" parentState={this.state} handleClick={this.clickElemento.bind(this)} />
                            <div className="elem" style={{"padding-top": "6px", "padding-left": "12px"}}>
                              {this.state.tipo === 'P' && <b><a href="#" onClick={ () => { this.setState({tipo: 'P'}); return false; } }>P</a></b>}
                              {this.state.tipo !== 'P' && <a href="#" onClick={ () => { this.setState({tipo: 'P'}); return false; } }>P</a>}
                              <br/>
                              {this.state.tipo === 'D' && <b><a href="#" onClick={ () => { this.setState({tipo: 'D'}); return false; } }>D</a></b>}
                              {this.state.tipo !== 'D' && <a href="#" onClick={ () => { this.setState({tipo: 'D'}); return false; } }>D</a>}
                            </div>
                          </Grid>
                        </Grid>  
                    </Grid>
                   
                  </Grid>
                  </>}
              </div>
              
             </Grid>
            
             <Grid item sx={12} style={{'margin-top': 20}}>
             <Grid container>
               <Grid item xs={3}>
               {this.state.piano_obj.stato === "In preparazione" &&  
               <TextField
                    name={'elementi'}
                    label={'Elementi selezionati'}
                    style={{ width: "100%" }}
                    options={options_elementi}
                    value={this.state.elementi.join(", ")}
                  />
               }
               </Grid>


                    
               <Grid item xs={9} align="right">       
             {this.state.piano_obj.stato === "In preparazione" &&
                  <Button value="aggiungi" variant="contained" color="primary" onClick={(val)=> {this.aggiungiPrestazione(val)}}>
                      Aggiungi prestazione
                  </Button>
             }
              </Grid>
                  
             </Grid> 
             </Grid>
             <Grid item sx={12} style={{'margin-top': 20}}>
             <Table dense={true}>
                {this.state.trattamenti_paziente.map((item) => {
                  let prodotto = ""
                  if(item.prodotto_assicurativo !== null && item.prodotto_assicurativo !== undefined) {  
                    prodotto = " - " + item.prodotto_assicurativo.nome
                  }
                  let stile = {}
                  if (item.annullato) {
                    stile = {
                      color: "#888888",
                      "text-decoration": "line-through"
                    }
                  }
                  return (
                  <TableRow style={stile}>
                    <TableCell><b>Elementi:</b><br/>
                      <Tooltip title="Eseguiti"><small style={{color: "#008500"}}>{amnp.join(item.elementi_eseguiti, ", ")}</small></Tooltip>&nbsp;
                      <Tooltip title="Da eseguire"><small style={{color: "#A60000"}}>{amnp.join(item.elementi_da_eseguire, ", ")}</small></Tooltip>
                    </TableCell>
                    <TableCell>
                      {item.annullato && <b>ANNULLATO</b>} {item.trattamento.nome} {prodotto}<br/>
                      {item.elementi_eseguiti.length > 0 && <small style={{color: "#008500"}}>Eseguito: {item.date_esecuzione}</small>}
                      {item.elementi_eseguiti.length > 0 && item.da_fare.length > 0 && <br/>}
                      {item.da_fare.length > 0 && <small style={{color: "#000"}}>Da fare: {amnp.join(item.da_fare, ", ")}</small>}
                      {(item.lista_fatture && item.lista_fatture.length > 0) && <><br/><small style={{color: "#008500"}}>Fatturato {amnp.join(item.lista_fatture, ", ")}</small></>}
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat value={Number(item.prezzo_base)} displayType={'text'} fixedDecimalScale={2} thousandSeparator={'.'} decimalSeparator={','} decimalScale={2} suffix={' EUR'} />
                      {(item.prezzo_base - item.prezzo_scontato > 0) && <><br/><small>Sconto: <NumberFormat value={item.prezzo_base - item.prezzo_scontato} displayType={'text'} fixedDecimalScale={2} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','} suffix={' EUR'} /></small></>}
                      {(item.prezzo_base - item.prezzo_scontato > 0) && <><br/><small>Prezzo scontato: <NumberFormat value={Number(item.prezzo_scontato)} displayType={'text'} fixedDecimalScale={2} decimalScale={2} thousandSeparator={'.'} decimalSeparator={','} suffix={' EUR'} /></small></>}
                    </TableCell>
                    <TableCell>
                    {this.state.piano_obj.stato !== "Non autorizzato" && this.state.piano_obj.stato !== "Da autorizzare" && this.state.piano_obj.stato !== "In preparazione" && !item.annullato && (item.elementi_eseguiti.length === 0  || item.elementi_da_eseguire.length > 0) &&
                      <Tooltip title="annulla trattamento"><Button variant="contained" size="small" color="default" onClick={() => { this.annulla(item.id) }}>annulla <Cancel/></Button></Tooltip>}
                    {this.state.piano_obj.stato !== "In preparazione" && item.annullato && (item.elementi_eseguiti.length === 0  || item.elementi_da_eseguire.length > 0) &&
                      <Tooltip title="annulla trattamento"><Button variant="contained" size="small" color="default" onClick={() => { this.annulla(item.id) }}>ripristina <Cancel/></Button></Tooltip>}  
                    {this.state.piano_obj.stato === "In preparazione" &&  item.elementi_eseguiti.length === 0 &&
                      <Tooltip title="rimuovi trattamento"><Button  variant="contained" size="small" color="default" onClick={() => { this.removeTrattamento(item.id) }}>Rimuovi <DeleteIcon /></Button></Tooltip>
                    }
                    </TableCell>
                  </TableRow>)
                  }
                )
                }

              </Table>
               {this.state.piano_obj.importato && this.state.piano_obj.extra_data  && this.state.piano_obj.extra_data.trattamenti_import_LI &&
               <><h5>Trattamenti presenti sui dati importati:</h5>
               <Table dense={true}>
                 <TableRow>
                   <TableCell><b>Trattamento</b></TableCell>
                   <TableCell><b>Dente</b></TableCell>
                   <TableCell align="right"><b>Data</b></TableCell>
                   <TableCell><b>Stato</b></TableCell>
                   <TableCell align="right"><b>Costo</b></TableCell>
                 </TableRow>
                 {this.state.piano_obj.extra_data.trattamenti_import_LI.map((item) => {
                  return(<TableRow>
                      <TableCell>
                        {item.trattamento.descrizione}
                      </TableCell>
                    <TableCell>
                        {item.dente}
                      </TableCell>
                    <TableCell align="right">
                        {item.data}
                    </TableCell>
                    <TableCell>
                        {item.stato || '--'}
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat value={item.costo} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>

                  </TableRow>);
               })}
               </Table><br/></>
               }
              <Table>
                  <TableRow>
                    <TableCell align="right">
                    TOTALE:
                    </TableCell>
                    <TableCell align="left">
                      <NumberFormat value={this.prezzoTotale()} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                  </TableRow>
                  <TableRow>  
                    <TableCell align="right">
                    SCONTO:
                    </TableCell>
                    <TableCell align="left">
                      {sconto_edit && (tipo_sconto === 'abs') && <><NumberFormat 
                        value={this.state.curr_sconto} 
                        defaultValue={0.00}
                        allowEmptyFormatting={true}
                        label="importo"
                        style={{width: 150}} 
                        customInput={TextField} 
                        fixedDecimalScale={2} 
                        decimalScale={2} 
                        decimalSeparator="," thousandSeparator="."
                        isAllowed={(values) => {
                          const {floatValue} = values
                          return floatValue >= 0 &&  floatValue <= this.prezzoTotale()
                        }}
                        onValueChange={(values)=> {this.cambiaSconto(values.floatValue)}}
                      />&nbsp;
                      <SingleSelect name={'tipo_sconto'}
                      label={'tipo'}
                      style={{width: 70}}
                      value={tipo_sconto}
                      options={[{value: 'abs', label: '- EUR -'}, {value: 'perc', label: '- % -'}]}
                      onInput={this.handleInputPrestazione}
                      onChange={(val)=> {this.setTipoSconto(val)}}
                      /></>
                      }
                      {sconto_edit && (tipo_sconto === 'perc') && <><NumberFormat 
                        value={parseInt(this.getScontoPerc())}
                        defaultValue={0.00}
                        allowEmptyFormatting={true}
                        style={{width: 150}} 
                        label="percentuale"
                        customInput={TextField} 
                        onValueChange={(values)=> {this.cambiaScontoPerc(values.floatValue)}}
                        isAllowed={(values) => {
                          const {floatValue} = values
                          return floatValue >= 0 &&  floatValue < 100
                        }}
                      />&nbsp;
                      <SingleSelect name={'tipo_sconto'}
                      label={'tipo'}
                      style={{width: 70}}
                      value={tipo_sconto}
                      options={[{value: 'abs', label: '- EUR -'}, {value: 'perc', label: '- % -'}]}
                      onInput={this.handleInputPrestazione}
                      onChange={(val)=> {this.setTipoSconto(val)}}
                      /></>
                      } 
                      {!sconto_edit && <><NumberFormat value={curr_sconto} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></>}
                    </TableCell>
                    <TableCell>
                      {this.state.piano_obj.stato === "In preparazione" && <Button variant="contained" color="default" onClick={() => { this.scontato(this.state.piano_obj.id) }}>IMPOSTA SCONTO</Button>}&nbsp;
                      {this.state.piano_obj.stato === "Scontato" && <Button variant="contained" color="default" onClick={() => { this.salvaSconto() }}>SALVA SCONTO</Button>}&nbsp;
                      {(this.state.piano_obj.stato === "In preparazione" || this.state.piano_obj.stato === "Scontato")&& <>
                      <Button variant="contained" color="default" onClick={() => { this.approvato(this.state.piano_obj.id, true) }}>Accetta</Button>&nbsp;&nbsp;
                      <Button variant="contained" color="default" onClick={() => { this.approvato(this.state.piano_obj.id, false) }}>Rifiuta</Button>&nbsp;&nbsp;
                     {this.state.in_modifica && <Button variant="contained" color="default" onClick={() => { this.undo_modifica_pdc(this.state.piano_obj.id) }}>Annulla modifiche</Button>}
                      </>}
                    </TableCell>
                  </TableRow>
                  <TableRow>  
                    <TableCell align="right">
                    TOTALE CON SCONTO:
                    </TableCell>
                    <TableCell align="left">
                    {sconto_edit && <NumberFormat 
                        value={this.prezzoTotale() - curr_sconto} 
                        defaultValue={0.00}
                        allowEmptyFormatting={true}
                        label="importo"
                        style={{width: 150}} 
                        customInput={TextField} 
                        fixedDecimalScale={2} 
                        decimalScale={2} 
                        decimalSeparator="," 
                        thousandSeparator="."
                        isAllowed={(values) => {
                          const {floatValue} = values
                          return floatValue >= 0 &&  floatValue <= this.prezzoTotale()
                        }}
                        onValueChange={(values)=> {this.cambiaScontato(values.floatValue)}}
                      />}

                      {!sconto_edit &&<NumberFormat value={this.prezzoTotale() - curr_sconto} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />}
                    </TableCell>
                  </TableRow>
                  {this.hasAnnullati() && <>
                  <TableRow>
                    <TableCell align="right">
                      ANNULLATO:
                     </TableCell>
                     <TableCell align="left">
                        <NumberFormat value={this.importoAnnullati()} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                     </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      NETTO:
                     </TableCell>
                     <TableCell align="left">
                        <NumberFormat value={Number(this.prezzoTotale() - curr_sconto - this.importoAnnullati())} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                     </TableCell>
                  </TableRow>
                  </>}
                  {false &&<>
                  <TableRow>  
                    <TableCell align="right">
                    ESEGUITO:
                    </TableCell>
                    <TableCell align="left">
                      <NumberFormat value={this.prezzoEseguito()} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                  </TableRow>
                  <TableRow>  
                    <TableCell align="right">
                    FATTURATO:
                    </TableCell>
                    <TableCell align="left">
                      <NumberFormat value={0} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                  </TableRow>
                  <TableRow>  
                    <TableCell align="right">
                    DA FATTURARE:
                    </TableCell>
                    <TableCell align="left">
                      <NumberFormat value={0} displayType={'text'} decimalSeparator="," thousandSeparator="." fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                    </TableCell>
                  </TableRow>
                  </>}
              </Table>
             </Grid>
            {in_preview && 
            <Grid item xs={12}>
              <PreviewPdf url_pdf={this.urlPdf()}/>
            </Grid>}
            
            
        </div>)
    }
}
export default withSnackbar(withConfirm(DiagrammaDentale))
